import { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
const baseUrl = "https://admin.test.readersfm.com";
export const SiteContext = createContext();

const ContextProvider = ({ children }) => {
  const [isUser, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedBooks, setSavedBooks] = useState(null);
  const [cartItem, setCartItem] = useState(
    JSON.parse(localStorage.getItem("_cart"))
  );
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [couponDis, setCouponDis] = useState(
    JSON.parse(localStorage.getItem("_coupon")) || 0
  );
  const [myBooks, setMyBooks] = useState(null);
  const [transition, setTransition] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("_token"));

        if (!token) {
          setLoading(false);
          return;
        }
        const response = await fetch(`${baseUrl}/api/transaction`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("_token")
            )}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLoading(false);
        setTransition(data?.data?.transactions);
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("_token"));

        if (!token) {
          setLoading(false);
          return;
        }

        const response = await fetch(`${baseUrl}/api/my-books`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("_token")
            )}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setLoading(false);
        setMyBooks(data?.data?.books);
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/new-save-books`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("_token")
            )}`,
          },
        });
        const data = await response.json();
        setSavedBooks(data?.data?.books);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (isUser) {
      fetchData();
    }
  }, [setLoading, setSavedBooks, isUser]);

  // Calculate total by applying the coupon
  const applyCoupon = (code) => {
    setLoading(true);
    if (cartItem) {
      const newTotal = cartItem.reduce(
        (acc, item) => acc + parseFloat(item.book_price),
        0
      );
      fetch(`${baseUrl}/api/order/check-coupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
        body: JSON.stringify({ total_amount: newTotal, coupon_code: code }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.code === 200) {
            Swal.fire("", `${data?.message}`, "success");
            setCouponDis(data?.data?.coupon_discount);
            localStorage.setItem(
              "_coupon",
              JSON.stringify(data?.data?.coupon_discount)
            );
            setLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Alert",
              text: `${data?.message}`,
            });
            setLoading(false);
          }
        });
    } else {
    }
  };

  // observer user auth state
  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem("_token")) {
      fetch(`${baseUrl}/api/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUser(data?.data);
          setLoading(false);
        });
    }
    setLoading(false);
  }, []);

  // Price calculation in cart page
  useEffect(() => {
    if (cartItem) {
      const subTotal = cartItem.reduce(
        (acc, item) => acc + parseFloat(item.book_price),
        0
      );

      let discountTotal = 0;
      for (const item of cartItem) {
        if (item.discount_type == "1") {
          // Flat rate discount
          discountTotal += parseFloat(item.book_discount);
        } else if (item.discount_type == "2") {
          // Percentage-based discount
          discountTotal +=
            (parseFloat(item.book_price) * parseFloat(item.book_discount)) /
            100;
        }
      }
      const total = subTotal - discountTotal;
      setSubTotal(subTotal);
      setDiscountTotal(discountTotal);
      setTotal(total);

      const priceData = {
        sub_total: subTotal,
        total: total,
        discount_total: discountTotal,
      };
      localStorage.setItem("_price", JSON.stringify(priceData));
    }
  }, [cartItem]);

  //Add to cart system
  const userName = JSON.parse(localStorage.getItem("name")) || null;
  const userToken = JSON.parse(localStorage.getItem("_token")) || null;

  const addToCartHandler = (book) => {
    setLoading(true);
    const book_id = book?.id;
    const prevCart = JSON.parse(localStorage.getItem("_cart")) || [];
    const existingItemIndex = prevCart.findIndex(
      (item) => item.book_id === book_id
    );

    if (existingItemIndex !== -1) {
      Swal.fire({
        icon: "error",
        title: "Alert",
        text: `Already added this item`,
      });
      setLoading(false);
      return;
    } else {
      const newItem = {
        book_id,
        user_id: isUser?.id,
        publisher_id: book?.publisher_id || null,
        book_name: book?.book_name,
        book_image: book?.book_image,
        book_price: book?.book_price,
        discount_type: book?.discount_type || null,
        book_discount: book?.book_discount || null,
      };
      prevCart.push(newItem);
    }

    if (userName && userToken) {
      localStorage.setItem("_cart", JSON.stringify(prevCart));
      fetch(`${baseUrl}/api/cart/add`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
        body: JSON.stringify({ book_id }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.code === 200) {
            Swal.fire("", `${data?.message}`, "success");
            localStorage.removeItem("_coupon");
            setLoading(false);
            fetchUpdatedCartData();
            window.location.reload();
          } else if (data?.code === 204) {
            Swal.fire({
              icon: "error",
              title: "Alert",
              text: `${data?.message}`,
            });
            setLoading(false);
          }
        })
        .catch((error) => {});
    } else {
      localStorage.setItem("_cart", JSON.stringify(prevCart));
      fetchUpdatedCartData();
      localStorage.removeItem("_coupon");
      Swal.fire("", `Item added to cart!`, "success");
      setLoading(false);
      window.location.reload();
    }
  };

  const buyPlanHandler = (plan) => {};

  // Function to fetch updated cart data
  const fetchUpdatedCartData = () => {
    if (isUser) {
      fetch(`${baseUrl}/api/cart/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCartItem(data.data?.carts);
        })
        .catch((error) => {
          console.log(error);
          console.error("Error fetching updated cart data:", error);
        });
    } else {
      setCartItem(JSON.parse(localStorage.getItem("_cart")));
    }
  };

  /*   useEffect(() => {
    if (userName && userToken) {
      fetch(`${baseUrl}/api/cart/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("_token"))}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCartItem(data.data?.carts);
        });
    }
  }, [userName, userToken]); */

  // Delete cart
  const deleteCart = async (cartId, book_name) => {
    setLoading(true);
    if (userName && userToken) {
      const prevCart = JSON.parse(localStorage.getItem("_cart")) || [];
      const updatedCart = prevCart.filter(
        (item) => item.book_name !== book_name
      );
      localStorage.setItem("_cart", JSON.stringify(updatedCart));
      try {
        const token = JSON.parse(localStorage.getItem("_token"));
        if (!token) {
          throw new Error("Authorization token not found in local storage");
        }
        const response = await fetch(`${baseUrl}/api/cart/remove/${cartId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(
            `Failed to delete cart item (HTTP ${response.status})`
          );
        }

        const data = await response.json();
        if (data?.code === 200) {
          fetchUpdatedCartData();
          setCouponDis(0);
          Swal.fire("", `${data?.message}`, "success");
          localStorage.removeItem("_coupon");
          setLoading(false);
          window.location.reload();
        } else if (data?.code === 204) {
          Swal.fire({
            icon: "error",
            title: "Alert",
            text: `${data?.message}`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const prevCart = JSON.parse(localStorage.getItem("_cart")) || [];
      const updatedCart = prevCart.filter(
        (item) => item.book_name !== book_name
      );
      localStorage.setItem("_cart", JSON.stringify(updatedCart));
      fetchUpdatedCartData();
      setCouponDis(0);
      localStorage.removeItem("_coupon");
      Swal.fire("", "Item remove from cart !", "success");
      setLoading(false);
    }
  };

  const Info = {
    baseUrl,
    isUser,
    setUser,
    loading,
    setLoading,
    addToCartHandler,
    cartItem,
    setCartItem,
    deleteCart,
    total,
    applyCoupon,
    couponDis,
    discountTotal,
    subTotal,
    savedBooks,
    setSavedBooks,
    myBooks,
    setMyBooks,
    transition,
    setTransition,
    buyPlanHandler,
  };

  return <SiteContext.Provider value={Info}>{children}</SiteContext.Provider>;
};

export default ContextProvider;
